.match {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.match > div {
    display: flex;
    flex-direction: row;
    border-radius: 7px;
    background-color: rgba(17, 16, 16, 0.9);
    padding: 10px;
    margin: 0 0 10px 0;
    width: auto;
    cursor: pointer;
}

.match > div > img {
    width: 100px;
    height: auto;
}

.match span {
    margin: 40px 20px;
    color: rgba(255, 255, 255, 0.788);
}
