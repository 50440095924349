.timer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.timer > span {
    font-weight: 400;
    font-size: 12vw;
    font-family: 'Roboto', monospace;
    line-height: 10vw;
}