.codeForm {
    display: flex;
    justify-content: center;
    align-items: center;
}

#code_input {
    color: rgb(0, 0, 0);
}

.codeForm button {
    margin-left: 10px;
}

 /* label focus color */
 .input-field input[type=text]:focus + label {
    color: rgb(255, 255, 255);
}
/* label underline focus color */
  .input-field input[type=text]:focus {
    border-bottom: 1px solid rgb(255, 255, 255);
    box-shadow: 0 1px 0 0 rgb(255, 255, 255);
  }