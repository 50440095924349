.scoreboard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.scoreboard img {
    width: 96%;
    height: auto;
}

.scoreboard .emblemCol {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
}

.scoreboard .middleCol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 15vw;
    width: 50%;
}

.scoreboard .middleCol > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 25vw;
}

.scoreboard-score {
    line-height: 22vw;
}
