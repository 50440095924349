* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: 'Roboto', sans-serif;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 245, 245) 100%);
  color: rgb(0, 0, 0)
}

#root {
  width: 100%;
  height: 100%;
}
